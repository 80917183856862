@tailwind base;
@tailwind components;
@tailwind utilities;

/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/

:root {
  --bgMain: #103030;
  --bgHeader: #000000;
  --txtNavHeader: #ffffff;
}

div.header {
  height: 3em;
  border-bottom: 1px solid var(--txtNavHeader);
  background-color: var(--bgHeader);
}

div.main {
  height: calc(100vh - 3em);
  width: 100%;
  overflow: auto;
  position: absolute;
  background-color: var(--bgMain);
}

div.navigation-bg {
  width: 100%;
  position: fixed;
  top: 3em;
  z-index: 50;
  background-color: var(--bgHeader);
}



/* navigation view */
div.navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  max-width: 50em;
  margin: auto;
}
div.navigation-group {
  width: 100%;
  color: var(--txtNavHeader);
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 2em;
  padding-right: 2em;
  text-align: left;
}
h1.navigation-group-title {
  font-size: 1.5rem;
  font-style: italic;
}
button.navigation-button:hover {
  text-decoration-line: underline;
}





div.main-content {
  max-width: 50em;
  margin: auto;
}

div.view-title {
  height: 3em;
  width: fit-content;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
